export const SPA_CAMPAIGN = 'Solution Play Accelerator(SPA)';
export const SPA_SubscriptionKey = "3A2504E0-4F89-11D3-9A0C-0305E82C3302";
export const SPA_CAMPAIGN_TEMPLATE_TYPE = 'SPA Campaign';
export const SPA_30MINS_IN_MILLISECONDS = 1800000;
export const SPA_APP_ID = "84a14d0b-7847-49e4-849b-c4e0fc4b45f4";
export const SPA_CAMPAIGN_TYPE_ATTRIBUTE= 'CampaignType';
//export const SPA_WORKFLOW_TYPE='Campaign';
export const SPA_ADMIN_MAIL="spaenggteam@microsoft.com";
const BULK_UPLOAD_ENTERPRISE_PATH ='bulkuploadenterpriseblob';
const BULK_UPLOAD_SMC_PATH ='bulkuploadsmcblob';
const BULK_UPLOAD_ENTERPRISE_CONNECTION ='campaign-bulkupload-enterprise-blob';
const BULK_UPLOAD_SMC_CONNECTION ='campaign-bulkupload-smc-blob';
export enum ConfigurationType{
    CAMPAIGN = 'Campaign',
    DATASET = 'Dataset'
}
export const UPPER_CASE="UPPER";
export const DATACART_COLUMN_STATUS = 'Add/Remove Accounts';
export const DATACART_COLUMN_SOURCE = 'Source';
export const ATTRIBUTE_REFERENCE_ID_KEY = "ReferenceId";
export const ATTRIBUTE_SEGMENT = "Segment";
export const ATTRIBUTE_ORG = "Org";
export const ENV_DEV = 'dev';
export const ENV_UAT = 'uat';
export const ENV_PROD = 'prod';
export const dataSetPrimaryFieldsForPublishMap: { [key: string]: string } = {
    "Text": "SourceRecText",
    "Reason": "SourceReasonText"
}
export enum CampaignStatusEnum {
    DRAFT="Draft",
    PUBLISHED="Published",
    DEPLOYED="Deployed"
}
export enum CampaignTypesEnum {
    SMC="SMC - Solution Play Accelerator",
    DSE="DSE - Solution Play Accelerator",
    Enterprise= "Enterprise - Solution Play Accelerator"
}
export const CampaignTypeToBlobPathMap = {
    [CampaignTypesEnum.SMC]: BULK_UPLOAD_SMC_PATH,
    [CampaignTypesEnum.DSE]: BULK_UPLOAD_SMC_PATH,
    [CampaignTypesEnum.Enterprise]: BULK_UPLOAD_ENTERPRISE_PATH
}
export const CampaignTypeToBlobConnectionMap = {
    [CampaignTypesEnum.SMC]: BULK_UPLOAD_SMC_CONNECTION,
    [CampaignTypesEnum.DSE]: BULK_UPLOAD_SMC_CONNECTION,
    [CampaignTypesEnum.Enterprise]: BULK_UPLOAD_ENTERPRISE_CONNECTION
}
export const CampaignTypes = [
    CampaignTypesEnum.SMC.toString(),
    CampaignTypesEnum.DSE.toString(),
    CampaignTypesEnum.Enterprise.toString()
];
export enum OrgTypesEnum {
    ENTERPRISE="Enterprise",
    SMC="SMC"
}
export const DefaultPublishValues = {
    IndexField: 'TPID',
    EffectiveDate: 'StartOnDate',
    DaysToAct: '90',
    DaysToExpire: '180',
    Family: 'Product',
    ConnectionName: 'TestPublishBlobNebula',
    DataPath: 'TestPublishBlob'
}

export enum CampaignFields {
    CampaignName = 'CampaignName',
    SubscriptionKey = 'SubscriptionKey',
    Description = 'Description',
    ExpiryDate = 'ExpiryDate',
    StartOn = 'StartOn',
    ActionByDate = 'ActionByDate',
    Tags = 'Tags',
    WorkflowId = 'WorkflowId',
    Version = 'Version'
}
export enum CampaignModelKeys {
    CampaignName = 'config.workflowName',
    ExpiryDate = 'expiryDate',
    StartOn = 'startOnDate',
    ActionByDate = 'actionByDate',
    Tags = 'tags'
}
export const CampaignFieldsForPublish = [
   //[ CampaignFields.CampaignName,CampaignModelKeys.CampaignName],
    [CampaignFields.ExpiryDate,CampaignModelKeys.ExpiryDate],
    [CampaignFields.StartOn,CampaignModelKeys.StartOn],
    [CampaignFields.ActionByDate,CampaignModelKeys.ActionByDate],
    [CampaignFields.Tags,CampaignModelKeys.Tags]
]

export const enum  DataTypes  { 
    AttributeValueType = 'String'
}

export enum QueryBuilderFields {
    TPID = "TPID",
    AccountName = "AccountName",
    SolutionPlay = "SolutionPlay",
    SolutionArea = "SolutionArea",
    Product = "Product",
    EstimatedValue = "EstimatedValue",
    Area = "Area",
    Region = "Region",
    Country = "Country",
    Segment = "Segment",
    SubSegment = "SubSegment",
    SubRegion = "SubRegion",
    Subsidiary = "Subsidiary",
    SalesUnit = "SalesUnit",
    Org = "Org",
    AccountTags = "AccountTags"
}

export const taxonomyExcludeFilters: {[key:string] : string[]} = {
    "salesMotion": ['Default']
}

export type MessageTypes = "error"|"info"|"notice"|"success"|"warning"|undefined;

export interface CampaignPublishTemplate {
    campaignPublishParameters: CampaignPublishParameter[];
}
export interface CampaignPublishParameter {
    type: string|undefined;
    publishSummaryFieldsList: FieldProperties[]|undefined;
    publishDataSetFieldsList: FieldProperties[]|undefined;
}
export interface FieldProperties {
    name: string;
    isPrimary: boolean;
    matchingMandatoryFieldMapping:string|undefined;
    alias:string|undefined;
    isOptional:boolean;
}
export enum PublishTemplatePropertyTypes {
    Alias = "alias",
    MatchingMandatoryFieldMapping = "matchingMandatoryFieldMapping",
    Optional="isOptional"
}
export enum SubTitles {
    MergeRuleTitle = 'All Merge Rules',
    CampaignTitle = 'SPA Management'
}

export const HelpTexts: {[key:string]: string} = {
    CampaignName: 'SPA/Always-On Campaign Name visible to the Sellers in Sales Accelerator',
    Description: 'Campaign objective, offer for the customer, target accounts criteria and Revenue Targets',
    Tags: 'Used for reporting to visualize all records under a campaign. Not visible to seller',
    ExpiryDate: 'Date the Campaign records will be retired from Sales Accelerator, no longer visible to the seller if unactioned. Not visible to the seller',
    StartOn: 'Target Go-Live Date for the campaign. Not visible to seller',
    ActionByDate: 'Date visible in the recommendation that alerts seller of when campaign/offer will expire',
    SolutionArea: 'Solution Area aligned to campaign',
    SolutionPlay: 'Solution Play aligned to campaign. Note: Only the Approved Solution Play associated with the Solution Area above will be displayed',
    Product: 'Product aligned to campaign. Note: Only the Approved Product associated with the Solution Play above will be displayed',
    SalesMotion: 'Sales strategy aligned with the campaign',
    RecommendationTitle: 'Provide a short summary of why this campaign is compelling to the customer. This will be displayed in the Insights section of the Recommendation',
    RecommendationText: 'Explanation of why customer was chosen.  Why is this customer currently ready to buy and why should the seller target the customer.  Also, additional details that would help the Seller. This will be displayed in the Insights section of the Recommendation.',
    DatasetName: 'Dataset Name visible to the Sellers in Campaign creation',
    DatasetDescription: 'Dataset objective, target accounts criteria and Revenue Targets',
}

export interface ICampaignMailConfigParams {
    UserObjectId: string,
    UserEmail: string,
    Username: string,
    Segment: string,
    SolutionArea: string
}

export interface ICampaignMailConfig {
    [key: string]: ICampaignMailConfigParams
}

export const CampaignAreaReviewerMailConfig: ICampaignMailConfig = {
    "Spa_Create_Review": {
        "UserObjectId": '95132a38-44ee-485e-9621-0d96db699447',
        "UserEmail": 'aspadris@microsoft.com',
        "Username": 'ASPA-DRIs',
        "Segment": "",
        "SolutionArea": "",
    },
    "Spa_Ent_Azure_DAI_Area_Reviewers": {
        "UserObjectId": 'a30fca8c-803c-43cf-957f-7f0abbe1a0be',
        "UserEmail": 'ASPA-Ent_Az_Reviewer@microsoft.com',
        "Username": 'ASPA-Ent_Azure-Area_Reviewers',
        "Segment": "Enterprise",
        "SolutionArea": "Data and AI",
    },
    "Spa_Ent_Azure_DIGI_Area_Reviewers": {
        "UserObjectId": 'a30fca8c-803c-43cf-957f-7f0abbe1a0be',
        "UserEmail": 'ASPA-Ent_Az_Reviewer@microsoft.com',
        "Username": 'ASPA-Ent_Azure-Area_Reviewers',
        "Segment": "Enterprise",
        "SolutionArea": "Digital and Application Innovation",
    },
    "Spa_Ent_Azure_INFRA_Area_Reviewers": {
        "UserObjectId": 'a30fca8c-803c-43cf-957f-7f0abbe1a0be',
        "UserEmail": 'ASPA-Ent_Az_Reviewer@microsoft.com',
        "Username": 'ASPA-Ent_Azure-Area_Reviewers',
        "Segment": "Enterprise",
        "SolutionArea": "Infrastructure",
    },
    "Spa_Ent_Business_Apps_Area_Reviewers": {
        "UserObjectId": '507a1f9a-dc8b-401d-81a6-b33eedec6799',
        "UserEmail": 'ASPA-Ent_BA_Reviewer@microsoft.com',
        "Username": 'ASPA-Ent_Business_Apps-Area_Reviewers',
        "Segment": "Enterprise",
        "SolutionArea": "Business Applications",
    },
    "Spa_Ent_Modern_Work_Area_Reviewers": {
        "UserObjectId": '314ab9c8-aac2-4a1d-9e2e-6ad4aaa86c27',
        "UserEmail": 'ASPA-Ent_MW_Reviewer@microsoft.com',
        "Username": 'ASPA-Ent_Modern_Work-Area_Reviewers',
        "Segment": "Enterprise",
        "SolutionArea": "Modern Work",
    },
    "Spa_Ent_Security_Area_Reviewers": {
        "UserObjectId": '2d10fea6-5078-4fb1-9ddf-dda908480bff',
        "UserEmail": 'ASPA-Ent_SA_Reviewer@microsoft.com',
        "Username": 'ASPA-Ent_Security-Area_Reviewers',
        "Segment": "Enterprise",
        "SolutionArea": "Security",
    },
    "Spa_Ent_Unified_Area_Reviewers": {
        "UserObjectId": '55a161bf-7029-4292-939d-2e6aa4f8a317',
        "UserEmail": 'ASPA-Ent_UA_Reviewer@microsoft.com',
        "Username": 'ASPA-Ent_Unified-Area_Reviewers',
        "Segment": "Enterprise",
        "SolutionArea": "Unified Support",
    },
    "Spa_SMCC_DSE_DAI-Area_Reviewers": {
        "UserObjectId": '3db09222-5e95-4961-98b5-33c1831dea61',
        "UserEmail": 'ASPA-SD_Az_Reviewer@microsoft.com',
        "Username": 'ASPA-SMCC_DSE_Azure-Area_Reviewers',
        "Segment": "SMC",
        "SolutionArea": "Data and AI",
    },
    "Spa_SMCC_DSE_DIGI-Area_Reviewers": {
        "UserObjectId": '3db09222-5e95-4961-98b5-33c1831dea61',
        "UserEmail": 'ASPA-SD_Az_Reviewer@microsoft.com',
        "Username": 'ASPA-SMCC_DSE_Azure-Area_Reviewers',
        "Segment": "SMC",
        "SolutionArea": "Digital and Application Innovation",
    },
    "Spa_SMCC_DSE_INFRA-Area_Reviewers": {
        "UserObjectId": '3db09222-5e95-4961-98b5-33c1831dea61',
        "UserEmail": 'ASPA-SD_Az_Reviewer@microsoft.com',
        "Username": 'ASPA-SMCC_DSE_Azure-Area_Reviewers',
        "Segment": "SMC",
        "SolutionArea": "Infrastructure",
    },
    "Spa_SMCC_DSE_Business-Apps-Area_Reviewers": {
        "UserObjectId": '090a8d06-8401-4f2b-b6e5-d7c639852755',
        "UserEmail": 'ASPA-SD_BA_Reviewer@microsoft.com',
        "Username": 'ASPA-SMCC/DSE_Business_Apps-Area_Reviewers',
        "Segment": "SMC",
        "SolutionArea": "Business Applications",
    },
    "Spa_SMCC_DSE_Modern_Work_Area_Reviewers": {
        "UserObjectId": '63e13435-22be-4b5c-928d-84b03e94b85d',
        "UserEmail": 'ASPA-SD_MW_Reviewer@microsoft.com',
        "Username": 'ASPA-SMCC_DSE_Modern_Work-Area_Reviewers',
        "Segment": "SMC",
        "SolutionArea": "Modern Work",
    },
    "Spa_SMCC_DSE_Security_Area_Reviewers": {
        "UserObjectId": 'd9527f38-6d30-4068-a112-7ad67b1680bb',
        "UserEmail": 'ASPA-SD_SA_Reviewer@microsoft.com',
        "Username": 'ASPA-SMCC/DSE_Security-Area_Reviewers',
        "Segment": "SMC",
        "SolutionArea": "Security",
    },
    "Spa_SMCC_DSE_Unified-Area_Reviewers": {
        "UserObjectId": 'c08c5f9f-06f7-4d02-9338-19381711336a',
        "UserEmail": 'ASPA-SD_UA_Reviewer@microsoft.com',
        "Username": 'ASPA-SMCC_DSE_Unified-Area_Reviewers',
        "Segment": "SMC",
        "SolutionArea": "Unified Support",
    }
}

export const CampaignVendorMailConfig: ICampaignMailConfig = {
    "ASPA-Ent_Azure_DAI": {
        "UserObjectId": '895c1507-5029-4de3-a71a-ddfeb2bf69e8',
        "UserEmail": 'ASPA-V-Ent_Az@microsoft.com',
        "Username": 'ASPA-Ent_Azure',
        "Segment": "Enterprise",
        "SolutionArea": "Data and AI",
    },
    "ASPA-Ent_Azure_DIGI": {
        "UserObjectId": '895c1507-5029-4de3-a71a-ddfeb2bf69e8',
        "UserEmail": 'ASPA-V-Ent_Az@microsoft.com',
        "Username": 'ASPA-Ent_Azure',
        "Segment": "Enterprise",
        "SolutionArea": "Digital and Application Innovation",
    },
    "ASPA-Ent_Azure_INFRA": {
        "UserObjectId": '895c1507-5029-4de3-a71a-ddfeb2bf69e8',
        "UserEmail": 'ASPA-V-Ent_Az@microsoft.com',
        "Username": 'ASPA-Ent_Azure',
        "Segment": "Enterprise",
        "SolutionArea": "Infrastructure",
    },
    "ASPA-Ent_Business_Apps": {
        "UserObjectId": '7b1be569-d2f1-4171-9f8f-94def396d4f8',
        "UserEmail": 'ASPA-V-Ent_BA@microsoft.com',
        "Username": 'ASPA-Ent_Business_Apps',
        "Segment": "Enterprise",
        "SolutionArea": "Business Applications",
    },
    "ASPA-Ent_Modern_Work": {
        "UserObjectId": '1d884002-ad8a-44d7-849a-f5df07f5c01d',
        "UserEmail": 'ASPA-V-Ent_MW@microsoft.com',
        "Username": 'ASPA-Ent_Modern_Work',
        "Segment": "Enterprise",
        "SolutionArea": "Modern Work",
    },
    "ASPA-Ent_Security": {
        "UserObjectId": '28fc82ac-59ab-4192-8740-e8669a4885ab',
        "UserEmail": 'ASPA-V-Ent_SA@microsoft.com',
        "Username": 'ASPA-Ent_Security',
        "Segment": "Enterprise",
        "SolutionArea": "Modern Work",
    },
    "ASPA-Ent_Unified": {
        "UserObjectId": '562d7d48-4fa8-418f-a523-bc4d965248b8',
        "UserEmail": 'ASPA-V-Ent_UA@microsoft.com',
        "Username": 'ASPA-Ent_Unified',
        "Segment": "Enterprise",
        "SolutionArea": "Unified Support",
    },
    "ASPA-SMCC/DSE_DAI": {
        "UserObjectId": '34f09425-0117-4adc-b344-ad748a7a8434',
        "UserEmail": 'ASPA-V-SMC_DSE_Az@microsoft.com',
        "Username": 'ASPA-SMCC/DSE_Azure',
        "Segment": "SMC",
        "SolutionArea": "Data and AI",
    },
    "ASPA-SMCC/DSE_DIGI": {
        "UserObjectId": '34f09425-0117-4adc-b344-ad748a7a8434',
        "UserEmail": 'ASPA-V-SMC_DSE_Az@microsoft.com',
        "Username": 'ASPA-SMCC/DSE_Azure',
        "Segment": "SMC",
        "SolutionArea": "Digital and Application Innovation",
    },
    "ASPA-SMCC/DSE_INFRA": {
        "UserObjectId": '34f09425-0117-4adc-b344-ad748a7a8434',
        "UserEmail": 'ASPA-V-SMC_DSE_Az@microsoft.com',
        "Username": 'ASPA-SMCC/DSE_Azure',
        "Segment": "SMC",
        "SolutionArea": "Infrastructure",
    },
    "ASPA-SMCC/DSE_Business_Apps": {
        "UserObjectId": '7fba5584-3767-4811-9396-a469ce8b0277',
        "UserEmail": 'ASPA-V-SMC_DSE_BA@microsoft.com',
        "Username": 'ASPA-SMCC/DSE_Business_Apps',
        "Segment": "SMC",
        "SolutionArea": "Business Applications",
    },
    "ASPA-SMCC/DSE_Modern_Work": {
        "UserObjectId": 'f0f90348-a781-476d-9c46-ed3a9f349dd6',
        "UserEmail": 'ASPA-V-SMC_DSE_MA@microsoft.com',
        "Username": 'ASPA-SMCC/DSE_Modern_Work',
        "Segment": "SMC",
        "SolutionArea": "Modern Work",
    },
    "ASPA-SMCC/DSE_Security": {
        "UserObjectId": '5e6ac78f-0d4a-428d-b045-94ac57b9465b',
        "UserEmail": 'ASPA--SMC_DSE_SA@microsoft.com',
        "Username": 'ASPA-SMCC/DSE_Security',
        "Segment": "SMC",
        "SolutionArea": "Security",
    },
    "ASPA-SMCC/DSE_Unified": {
        "UserObjectId": 'c6d5a195-b3ea-4691-9699-38f9b9658f4c',
        "UserEmail": 'ASPA-V-SMC_DSE_UA@microsoft.com',
        "Username": 'ASPA-SMCC/DSE_Unified',
        "Segment": "SMC",
        "SolutionArea": "Unified Support",
    }
}

export const MsxLinks: {[key: string]: string} = {
    'dev': 'https://msxuat.crm.dynamics.com/main.aspx?appid=fe0c3504-3700-e911-a849-000d3a10b7cc&pagetype=control&controlName=MscrmControls.Segment.SegmentHostControl',
    'uat': 'https://msxuat.crm.dynamics.com/main.aspx?appid=fe0c3504-3700-e911-a849-000d3a10b7cc&pagetype=control&controlName=MscrmControls.Segment.SegmentHostControl',
    'prod': 'https://microsoftsales.crm.dynamics.com/main.aspx?appid=fe0c3504-3700-e911-a849-000d3a10b7cc&pagetype=control&controlName=MscrmControls.Segment.SegmentHostControl'
}

export const PowerBiLinks: {[key: string]: string} = {
    'dev': 'https://msit.powerbi.com/groups/3955b31a-8b11-4212-98d5-502b0928c9cc/reports/8aa941d7-d31c-4524-b8db-d3b41b50dcb5/ReportSection?experience=power-bi&filter=aspa_track_predictions_dataverse/CampaignTag eq ',
    'uat': 'https://msit.powerbi.com/groups/3955b31a-8b11-4212-98d5-502b0928c9cc/reports/8aa941d7-d31c-4524-b8db-d3b41b50dcb5/ReportSection?experience=power-bi&filter=aspa_track_predictions_dataverse/CampaignTag eq ',
    'prod': 'https://msit.powerbi.com/groups/3955b31a-8b11-4212-98d5-502b0928c9cc/reports/e5dae275-27e9-4407-8500-bc233ad890c8/ReportSection?experience=power-bi&filter=aspa_track_predictions_dataverse/CampaignTag eq '
}
export const PreSummaryPowerBiLink : {[key: string]: string} = {
    'dev': 'https://msit.powerbi.com/groups/3955b31a-8b11-4212-98d5-502b0928c9cc/reports/b59626b3-8e82-44d9-b1ba-1ae2abdbf8d4/f9dd35fa4c89346384d8?experience=power-bi&filter=campaignpresummary/CampaignTag eq ',
    'uat': 'https://msit.powerbi.com/groups/3955b31a-8b11-4212-98d5-502b0928c9cc/reports/fabe18d1-7b76-4e82-b79a-c986cd8fced1/d4fe92a84589ca302d8d?experience=power-bi&filter=campaignpresummary/CampaignTag eq ',
}
export const Environments: {[key: string]: string} = {
    'DEV': 'dev',
    'UAT': 'uat',
    'PROD': 'prod'
}
export const getQueryConditionByCampaignType = (campaignType: string) => {
    switch(campaignType){
        case CampaignTypesEnum.SMC:
            return 'smc';
        case CampaignTypesEnum.DSE:
            return 'dse';
        case CampaignTypesEnum.Enterprise:
            return 'EXISTS (SELECT 1 FROM ';
        default:
            return '';
    }
}
export const SEGMENT_FILTERS_MAP:{ [key: string]: string[] } = {
    [CampaignTypesEnum.Enterprise]: ['Major','Strategic'],
    [CampaignTypesEnum.SMC]: ['Small']
}
export const WORKFLOW_TYPE_DATASET = 'Dataset';
export const WORKFLOW_DATASETTYPE_ML_MODEL = 'ML Model';
export const DSE_SEARCH_TAG='SearchTag';
export const DSE_SEARCH_TAGS='Tags';
export const DSE_SEARCH_SOLUTIONPLAY='SolutionPlay';
export const NA='N/A';
export type DSE_SEARCH_CONFIG ={
    DSE_SEARCH_TAG:string,
    DSE_SEARCH_TAGS:Array<string>,
    DSE_SEARCH_SOLUTIONPLAY:string
}
export const DEFAULT_DSE_CONFIG = {
    DSE_SEARCH_TAG: NA,
    DSE_SEARCH_SOLUTIONPLAY: NA,
    DSE_SEARCH_TAGS: [NA]
}
export const DSE_CONFIGS = {
    "Modern Work": [{
        ...DEFAULT_DSE_CONFIG,
        DSE_SEARCH_TAG:"DSE Modern Work"
    }],
    "Infrastructure": [{
        ...DEFAULT_DSE_CONFIG,
        DSE_SEARCH_TAG:"DSE Azure",
    }],
    "Data and AI": [{
        ...DEFAULT_DSE_CONFIG,
        DSE_SEARCH_TAG:"DSE Azure"
    }],
    "Digital and Application Innovation":[{
        ...DEFAULT_DSE_CONFIG,
        DSE_SEARCH_TAG:"DSE Azure"
    }],
    "Security": [{
        ...DEFAULT_DSE_CONFIG,
        DSE_SEARCH_TAG:"DSE Security",
    }],
    "Business Applications": [
        {
            ...DEFAULT_DSE_CONFIG,
            DSE_SEARCH_TAGS:["DSE D365","DSE Low Code"]
        },
        {
            ...DEFAULT_DSE_CONFIG,
            DSE_SEARCH_SOLUTIONPLAY:"Accelerate Innovation with Low Code",
            DSE_SEARCH_TAGS:["DSE Low Code"]
        }
    ]
}
export enum ADBRunStatus {
    SUCCESS="SUCCESS",
    FAILED="FAILED"
}